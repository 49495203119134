import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import ErrorPage, { ErrorPageProps } from 'templates/ErrorPage';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { PageTitle } from 'helpers/eventTracking';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import useResetReduxState from 'helpers/useResetReduxState';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-session-expired" }) {
      ...ErrorPage
    }
  }
`;

/* istanbul ignore next */
const SessionExpiredPage: React.FC<ErrorPageProps> = ({ data }) => {
  usePageTracking(data.csErrorPage.meta_title);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.error);

  const resetReduxState = useResetReduxState();

  useEffect(() => {
    resetReduxState();
  }, [resetReduxState]);

  return (
    <ErrorPage
      data={data}
      sessionExpiryOption={SessionExpiryOption.NO_SESSION_EXPIRY}
      pageTitle={PageTitle.SessionEnded}
    />
  );
};

export default SessionExpiredPage;
